import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyCodeBlock = lazy(
  () => import("./code-block-DqAZz_Xo.js").then((module) => ({
    default: module.CodeBlock
  }))
);
function CodeBlock({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyCodeBlock, { ...props });
}
const useCodeBlockContent = () => {
  return useMemo(
    () => ({
      codeBlock: {
        component: CodeBlock
      }
    }),
    []
  );
};
export {
  LazyCodeBlock as CodeBlock,
  useCodeBlockContent
};
